import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  TextField,
  Breadcrumbs,
  Typography,
  InputAdornment,
  Dialog,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  Select,
  MenuItem,
  OutlinedInput,
  SelectProps
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { DeleteOutline, Search } from "@material-ui/icons";
import { cancelIcon, editButton, filterImage, userImage } from "./assets";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Pagination } from "@material-ui/lab";
import { I18n } from "../../../components/src/languageJson/i18n";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// Customizable Area End

import AccountGroupsController, {
  configJSON,
  Props,
} from "./AccountGroupsController";

export default class AccountGroups extends AccountGroupsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <MainBox>
        <div style={{ paddingBottom: '50px' }}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            <Typography color="inherit">
            {I18n.t("home")}
            </Typography>
            <Typography color="primary" style={{ fontWeight: '700'}}>
            {I18n.t("peopleManagement")}
            </Typography>
          </Breadcrumbs>
        </div>

        <div className="search_wrapper" style={{ textAlign: 'center'}}>
        <SearchBox className="addclass">
          <TextField
            variant="outlined"
            placeholder="Search"
            className="search-bar searchInput"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              )
            }}
          />
          <div onClick={this.handleClickOpen} className="filterBtn"><img src={filterImage} alt="filter_img" /></div>
        </SearchBox>
        </div>

        <MainTableDiv>
          <TableMain className="mainoftable">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ borderBottomLeftRadius: '20px' }}>{I18n.t("employeeName")}</TableCell>
                  <TableCell>{I18n.t("employeeID")}</TableCell>
                  <TableCell>{I18n.t("department")}</TableCell>
                  <TableCell>{I18n.t("position")}</TableCell>
                  <TableCell>{I18n.t("reportingTo")}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                      <div><img src={userImage} alt="user_img" /></div>
                      <div><Typography>Ankit Patel</Typography></div>
                    </div>
                  </TableCell>
                  <TableCell>#5678945</TableCell>
                  <TableCell>Services</TableCell>
                  <TableCell>Software Developer</TableCell>
                  <TableCell>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                      <div><img src={userImage} alt="user_img" /></div>
                      <div><Typography>Ankit Patel</Typography></div>
                    </div>
                  </TableCell>
                  <TableCell><div style={{ display: 'flex',gap: '15px'}}><DeleteOutline style={{ color: 'red' }} /> <img src={editButton} alt="edit_button" /> </div></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                      <div><img src={userImage} alt="user_img" /></div>
                      <div><Typography>Piyush Patel</Typography></div>
                    </div>
                  </TableCell>
                  <TableCell>#7385555</TableCell>
                  <TableCell>Operations</TableCell>
                  <TableCell>Quality Assurance</TableCell>
                  <TableCell>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                      <div><img src={userImage} alt="user_img" /></div>
                      <div><Typography>Aman Yadav</Typography></div>
                    </div>
                  </TableCell>
                  <TableCell><div style={{ display: 'flex',gap: '15px'}}><DeleteOutline style={{ color: 'red' }} /><img src={editButton} alt="edit_button" /></div></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableMain>

          <div className="setPagination">
            <Pagination count={11} boundaryCount={1} siblingCount={1} />
            <div style={{ textAlign: 'end'}}>
            <Typography className="setCount">1-12 of 48 results</Typography>
            </div>
          </div>
        </MainTableDiv>

        <AddButton>
          <Button className="addBtn">{I18n.t("addNewEmployee")}</Button>
        </AddButton>

        <FilterDialog className="setDynamic" onClose={this.handleClose} aria-labelledby="simple-dialog-title" open={this.state.filterModalOpen}>
          <div className="mainFilter">
            <div>
              <Typography className="filterText">Filters</Typography>
            </div>
            <div className="innerFilter">
              <div>
                <Typography className="clearText" onClick={this.clearFilter}>Clear all</Typography>
              </div>
              <div onClick={this.handleClose}>
                <img src={cancelIcon} alt="cancel_icon" />
              </div>
            </div>
          </div>
          <br />

         {/* accordion for filterModalOpen */}
          <AccordionBox className="setAccroedion">
              <Accordion key={1} style={{ marginBottom: '8px', height: 'auto', padding: '0' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  style={{ minHeight: '40px', padding: '0' }} // Adjust the minimum height here
                >
                  <Typography className="accText">Department</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <FormControl variant="outlined" fullWidth>
                  <Select
                    style={{ borderRadius: '10px' }}
                    label="Select here"
                    name="department"
                    data-test-id="department_id"
                    displayEmpty
                    value={this.state.setDepartment}
                    onChange={(event) => this.handleChange("department", event)}
                    renderValue={(value) => {
                      const selectedDepartment = this.state.setDepartmentData?.find(
                        (item: any) => item.id === value
                      );
                      return (
                        <Box
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            color: "currentColor",
                            opacity: "0.42",
                          }}
                        >
                          {selectedDepartment ? selectedDepartment.attributes.name : "Department"}
                        </Box>
                      );
                    }}
                    input={<OutlinedInput />}
                  >
                    <MenuItem disabled value="">
                      Department
                    </MenuItem>
                    {this.state.setDepartmentData &&
                      this.state.setDepartmentData.map((item: any) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.attributes.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                </AccordionDetails>
              </Accordion>

              <Accordion key={2} style={{ marginBottom: '8px', height: 'auto', padding: '0' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  style={{ minHeight: '40px', padding: '0' }} // Adjust the minimum height here
                >
                  <Typography className="accText">Position</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      style={{ borderRadius: '10px' }}
                      label="Select here"
                      name="position"
                      data-test-id='position_id'
                      displayEmpty
                      value={this.state.setPosition}
                      onChange={(event) => this.handleChange("position",event)}
                      renderValue={(value: SelectProps['value']) => (
                        <Box style={{ display: "flex", flexWrap: "wrap", color: 'currentColor', opacity: '0.42' }}>
                          {value
                            ? value as string
                            : 'Position'}
                        </Box>
                      )}
                      input={<OutlinedInput />}
                    >
                      <MenuItem disabled value="">
                      Position
                      </MenuItem>
                      {
                      this.state.setPositionData && this.state.setPositionData.map((item: any, index: number) =>
                      (
                        <MenuItem key={index} value={item.name} > {item.name} </MenuItem>
                      )
                      )
                    }
                    </Select>
                  </FormControl>
                </AccordionDetails>
              </Accordion>

              <Accordion key={3} style={{ marginBottom: '8px', height: 'auto', padding: '0' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  style={{ minHeight: '40px', padding: '0' }} // Adjust the minimum height here
                >
                  <Typography className="accText">Reporting To</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      style={{ borderRadius: '10px' }}
                      label="Select here"
                      name="reporting_To"
                      data-test-id='reporting_id'
                      value={this.state.setReporting}
                      onChange={(event) => this.handleChange("reporting",event)}
                      displayEmpty
                      renderValue={(value: SelectProps['value']) => (
                        <Box style={{ display: "flex", flexWrap: "wrap", color: 'currentColor', opacity: '0.42' }}>
                          {value
                            ? value as string
                            : 'Reporting To'}
                        </Box>
                      )}
                      input={<OutlinedInput />}
                    >
                      <MenuItem disabled value="">
                      Reporting To
                      </MenuItem>
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                      <MenuItem value={40}>Fourty</MenuItem>

                    </Select>
                  </FormControl>
                </AccordionDetails>
              </Accordion>
          </AccordionBox>

          <div className="setApplyBtn">
            <button className="applyBtn">Apply</button>
          </div>

          </FilterDialog>
      </MainBox>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainBox = styled(Box)({
  padding: '50px 20px',
  background: '#F1F5FC',
  height: '100vh'
});

const AccordionBox = styled(Box)({
  width: '100%',
  '& .accText':{
    fontWeight: 700,
    color:'#64748B',
  },
  '& .MuiPaper-root':{
    '& .MuiButtonBase-root':{
      padding: '0px 20px !important'
    },
    '& .MuiCollapse-root':{
      background: '#F8FAFC',
      '& .MuiCollapse-wrapper':{
        '& .MuiCollapse-wrapperInner':{
          '& .MuiAccordionDetails-root':{
            padding: '0px 28px'
          }
        }
      }
    }
  }
});

const SearchBox = styled(Box)({
  textAlign: 'center',
  position: 'relative',
  display: 'inline-block',
  '& .searchInput':{
    '& .MuiInputBase-root':{
      borderRadius: '4px',
      borderTopLeftRadius: '40px',
      background: 'white',
      borderBottomLeftRadius: '40px',
      '& .MuiOutlinedInput-input':{
        padding: '10px 0px',
        width: '500px',
        '@media (max-width: 600px)': {
          width: '100%'
        }
      },
    }
  },
  '& .filterBtn':{
    position: 'absolute',
    top: '0px',
    right: '-15px',
    padding: '0px 10px',
    background: '#527FE2',
    borderTopRightRadius: '40px',
    borderBottomRightRadius: '40px',
    display: 'flex',
    alignItems: 'center',
    height: '100%'
  }
});
const TableMain = styled(TableContainer)({
  '& .MuiTable-root': {
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        '& .MuiTableCell-root': {
          borderBottom: 'none'
        }
      }
    },
    '& .MuiTableHead-root': {
      '& .MuiTableRow-root': {
        '& .MuiTableCell-stickyHeader': {
          backgroundColor: '#527FE2',
          color: 'white',
          fontWeight: 700
        }
      }
    }
  }
});

const MainTableDiv = styled(Box)({
  marginTop: '20px',
  padding: '20px',
  border: '1px solid #c8c8c8',
  borderRadius: '20px',
  background: 'white',
  '& .setPagination':{
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '30px',
    marginTop: '10px',
    position: 'relative',
    '@media (max-width: 600px)': {
        flexDirection: 'column',
        },
    '& .setCount':{
      position: 'absolute',
      right: '0', 
      top:'50%', 
      color: '#64748B',
      transform: 'translateY(-50%)',
      '@media (max-width: 600px)': {
        position: 'static',
        marginTop: '10px',
        transform: 'none',
        textAlign: 'center'
        }
    }
  },
});

const AddButton = styled(Box)({
  marginTop: '30px',
  textAlign: 'center',
  '& .addBtn': {
    padding: '10px 80px',
    border: 'none',
    background: '#527FE2',
    borderRadius: '8px',
    color: 'white',
    fontSize: '16px',
    fontWeight: 700,
    textTransform: 'capitalize'
  }
});

const FilterDialog = styled(Dialog)({
  position: 'relative',
  '& .setApplyBtn': {
    position: 'absolute',
    bottom: 0,
    right: 0,
    padding: '20px'
  },
  '& .applyBtn':{
    padding: '10px 30px',
    background: '#527FE2',
    color: 'white',
    fontWeight: 700,
    borderRadius: '8px',
    border: 'none'
  },
  '& .mainFilter':{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px',
    borderBottom: '1px solid #dddad6'
    },
  '& .innerFilter': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '20px'
  },
  '& .filterText':{
    fontWeight: 700
  },
  '& .clearText': {
    fontWeight: 400,
    color:'#527FE2',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  '& .MuiDialog-container':{
    position: 'absolute',
    right: '0',
    '& .MuiPaper-root':{
      width: '444px',
      height: '100%',
      maxHeight: '100%',
      boxShadow: 'none',
      margin: '0',
      '@media (max-width: 500px)': {
        width: '350px',
      },
    },
  }
});
// Customizable Area End
